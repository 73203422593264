<template>
  <section>
    <section class="search-container">
      <a-row type="flex">
        <a-col :span="21">
          <a-form
            :form="purchaseForm"
            layout="inline"
            @submit="submitForm"
            class="m-t-14"
          >
            <a-form-item label="供货商">
              <a-input
                class="search-input"
                v-decorator="['supplier']"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="订单编号">
              <a-input
                class="search-input"
                v-decorator="['code']"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="单据时间">
              <a-range-picker
                style="width: 240px"
                class="search-compact-select"
                v-decorator="['time']"
              >
                <a-icon slot="suffixIcon" type="calendar" />
              </a-range-picker>
            </a-form-item>
            <a-form-item label="订单状态">
              <a-select
                class="search-select"
                placeholder="请选择"
                @change="handleChangeStatus"
                v-decorator="['status']"
              >
                <a-select-option value="0">未入库 </a-select-option>
                <a-select-option value="1">部分入库 </a-select-option>
                <a-select-option value="2">全部入库 </a-select-option>
                <a-select-option value="3">已关闭 </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" class="m-r-8" html-type="submit">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :span="3">
          <a-row class="m-t-18" type="flex" justify="end">
            <a-col>
              <router-link to="/mms/purchase/addlist">
                <a-button type="primary" class="m-r-8" html-type="submit">新增采购</a-button>
              </router-link>
              <a-button
                class="item"
                @click="exprotFile('materialOrderExport', { params: params })"
                ><a-icon type="export" />导出</a-button
              >
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
        @change="changePageNum"
        :pagination="{ showQuickJumper: false, current:list.pageNum,total: list.total }"
      >
        <template slot="status" slot-scope="list, text">
          <span>{{
            text.status == "0"
              ? "未入库"
              : text.status == "1"
              ? "部分入库"
              : text.status == "2"
              ? "全部入库"
              : "已关闭"
          }}</span>
        </template>
        <template slot="operation" slot-scope="list, text">
          <!-- v-if="text.status == '0' || text.status == '1'" -->
          <span v-if="text.status == '0' || text.status == '1'">
            <router-link
              :to="
                '/mms/purchase/addbatch?supplier=' +
                text.supplier +
                '&&orderId=' +
                text.id
              "
            >
              <a style="color: #529aff">新增批次</a>
            </router-link>
            <a-divider type="vertical" />
          </span>

          <router-link :to="'/mms/purchase/order?id=' + text.id">
            <a style="color: #529aff">查看</a>
          </router-link>

          <span
            v-if="
              text.status == '3' || text.status == '2' || text.status == '0'
            "
          >
            <span
              v-if="
                text.status == '3' 
              "
            >
              <a-divider type="vertical" />
              <a style="color: #529aff" @click="deleteOrder(text.id)">删除</a>
            </span>
            <span v-if="text.status == '0'">
              <a-divider type="vertical" />
              <a style="color: #529aff" @click="closeOrder(text.id)">关闭</a>
            </span>
          </span>
          <!-- <span>
            <a-divider type="vertical" />
            <a style="color: #529aff"  @click="closeOrder(text.id)">关闭</a>
          </span> -->
        </template>
      </a-table>
    </section>
  </section>
</template>
<script>
import { exprotFile } from "@/until/unit";
export default {
  data() {
    return {
      params: {},
      purchaseForm: this.$form.createForm(this),
      code: "",
      startTime: "",
      endTime: "",
      manager: "",
      status: "",
      supplier: "",

      name: "",
      list: {
        columns: [
          { title: "序号", dataIndex: "key", align: "center" },
          { title: "单据时间", dataIndex: "orderDate", align: "center" },
          { title: "采购订单编号", dataIndex: "code", align: "center" , width:'200px'},
          { title: "供货商", dataIndex: "supplier", align: "center", width:'200px',ellipsis: true,  },
          { title: "实采金额合计(元)", dataIndex: "actualAmount", align: "center", width:'200px', },
          { title: "采购数量合计", dataIndex: "orderQty", align: "center" },
          { title: "收货数量合计", dataIndex: "receivedQty", align: "center" },
          {
            title: "订单状态",
            dataIndex: "status",
            align: "center",
            scopedSlots: { customRender: "status" },
          },
          { title: "备注", dataIndex: "remarks", align: "center",width:'200px', ellipsis: true, },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            width:'200px',
            scopedSlots: { customRender: "operation" },
          },
        ],
        data: [],
        total: 0,
        pageNum: 1,
      },
    };
  },
  created() {
    this.getOrderList();
  },
  methods: {
    exprotFile,
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.purchaseForm.getFieldsValue();
      console.log(formVal);
      this.code = formVal.code;
      this.status = formVal.status;
      this.list.pageNum = 1;
      this.startTime = formVal.time ? formVal.time[0].format("YYYY-MM-DD") : "";
      this.endTime = formVal.time ? formVal.time[1].format("YYYY-MM-DD") : "";
      this.supplier = formVal.supplier;
      this.getOrderList();
    },
    // 重置
    reset() {
      this.code = "";
      this.status = "";
      this.startTime = "";
      this.endTime = "";
      this.supplier = "";
      this.list.pageNum = 1;
      // 重置
      this.purchaseForm.resetFields();
      this.getOrderList();
    },
    // 关闭采购单
    closeOrder(id) {
      this.$api.closeOrder(id).then((res) => {
        if (res.code == "200") {
          this.getOrderList();
        }
      });
    },
    // 删除采购单
    deleteOrder(id) {
      this.$api.deleteOrder(id).then((res) => {
        if (res.code == "200") {
          this.getOrderList();
        }
      });
    },
    // 获取采购清单
    getOrderList() {
      this.params = {
        code: this.code,
        current: this.list.pageNum,
        endTime: this.endTime,
        startTime: this.startTime,
        status: this.status,
        supplier: this.supplier,
      };
      this.$api.getOrderList(this.params).then((res) => {
        if (res.code == "200") {
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.key = index + 1;
            });
          }
          this.list.data = res.data.records;
          this.list.total = res.data.total;
        }
      });
    },
    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.getOrderList();
    },
    // // 导出
    // exportList() {
    //   let params = {
    //     code: this.code,
    //     current: this.list.pageNum,
    //     endTime: this.endTime,
    //     startTime: this.startTime,
    //     status: this.status,
    //     supplier: this.supplier
    //   };
    //   this.$api.getOrderExport(params).then(res => {
    //     if(res.code=='200'){
    //         console.log('11')
    //     }
    //   });
    // },
    // 选择状态
    handleChangeStatus() {},
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
</style>