import Axios from 'axios'

/**
 * 导出文件
 * @param {*} type  导出接口类型
 * @param {*} opts  参数配置
 *                  params 导出参数
 *                  dateFormat 时间格式参数 默认为 'YYYY-MM-DD'
 *                  dateArray 时间区间[keyName(开始时间)、keyName(结束时间)]
 *                  fileType 导出文件格式  默认为.xlsx（excel文件）
 */
export function exprotFile(type, opts) {
    //导出api列表
    const apiList = {
        materialOrderExport: '/material/order/export', //材料订单-导出
        companyPaySalaryPersonnelExprot:
            '/personnel/companyPaySalaryPersonnel/excel', //薪资统计-导出
        qualityRecordExcel: '/quality-service/qualityRecord/excel', //质量巡查记录-导出
        safeRecordExcel: '/safe-service/safeRecord/excel', //安全巡查记录-导出
        safeJobExcel: '/safe-service/safeJob/excel', //安全作业-导出
        safeEducationExcel: '/safe-service/safeEducation/excel', //安全教育-导出
        materialStockExport: '/material/stock/export', //出入库管理库存-导出
        materialInStorageExport: '/material/inStorage/export', //入库记录-导出
        materialOutStorageExport: '/material/outStorage/export', //出库记录-导出
        fileDownload: '/personnel/upload/download.do', //文件下载
        exportModel: '/personnel/personnel/exportModel',//人员登记-下载模板
        exportPersonnel: '/personnel/personnel/export',//人员信息导出
        exportPersonnelLogList: '/personnel/personnelFaceCollectionLog/export',//人员出入台账导出
        exportTemplate: '/personnel/carInfo/exportTemplate', //车辆导入模板下载
        exportCar: '/personnel/carInfo/export', //车辆导出
        exportCarLogList:'/personnel/carInOutLog/exportCarLogList',//出入记录-导出
    }
    //导出接口请求参数 - data
    let params = { ...opts.params }
    //导出接口请求参数 - 时间格式参数格式化
    let dateFormat = opts.dateFormat || 'YYYY-MM-DD'
    //导出接口请求参数 - 时间区间[keyName(开始时间)、keyName(结束时间)]
    let dateArray = opts.dateArray || []
    //导出文件格式  默认为.xlsx（excel文件）
    let fileType = opts.fileType || '.xlsx'

    //当参数中出现时间对象时，转换为相应更新的字符转
    for (let key in params) {
        if (params[key] && params[key]._isAMomentObject) {
            params[key] = params[key].format(dateFormat)
        }
        //当接口请求参数 有区间时间数组时([moment,moment])
        if (
            Array.isArray(params[key]) &&
            params[key].length &&
            dateArray.length
        ) {
            for (let i = 0; i < dateArray.length; i++) {
                params[dateArray[i]] = params[key][i]._isAMomentObject
                    ? params[key][i].format(dateFormat)
                    : params[key][i]
            }
        }
    }
    Axios({
        method: 'get',
        url: apiList[type],
        params,
        responseType: 'blob' //blob/arraybuffer
    })
        .then((res) => {
            //创建文件对象
            let objectUrl = URL.createObjectURL(new Blob([res]))
            // 文件地址下载
            const link = document.createElement('a')
            let fileName ='';
            if(type == 'exportModel'){//人员导入模板下载
                fileName = '人员导入模板';
            }else if(type == 'exportPersonnel'){//人员档案导出
                fileName = '人员档案';
            }
            else if(type == 'exportPersonnelLogList'){//人员出入台账导出
                fileName = '人员出入台账';
            }
            else if(type == 'exportTemplate'){//车辆档案导出
                fileName = '车辆档案导入模板';
            }
            else if(type == 'exportCar'){//车辆档案导入模板下载
                fileName = '车辆档案';
            }else if(type == 'exportCarLogList'){//车辆出入记录导出
                fileName = '车辆出入记录';
            }
            else{
                fileName = new Date().getTime();
            }
            
            link.download = fileName + fileType
            link.href = objectUrl
            link.click()
        })
        .catch(() => {
            console.log('服务器异常，导出失败')
        })
}
//56个名族
export const nationalList = [
    '汉族',
    '蒙古族',
    '满族',
    '朝鲜族',
    '赫哲族',
    '达斡尔族',
    '鄂温克族',
    '鄂伦春族',
    '回族',
    '东乡族',
    '土族',
    '撒拉族',
    '保安族',
    '裕固族',
    '维吾尔族',
    '哈萨克族',
    '柯尔克孜族',
    '锡伯族',
    '塔吉克族',
    '乌孜别克族',
    '俄罗斯族',
    '塔塔尔族',
    '藏族',
    '门巴族',
    '珞巴族',
    '羌族',
    '彝族',
    '白族',
    '哈尼族',
    '傣族',
    '僳僳族',
    '佤族',
    '拉祜族',
    '纳西族',
    '景颇族',
    '布朗族',
    '阿昌族',
    '普米族',
    '怒族',
    '德昂族',
    '独龙族',
    '基诺族',
    '苗族',
    '布依族',
    '侗族',
    '水族',
    '仡佬族',
    '壮族',
    '瑶族',
    '仫佬族',
    '毛南族',
    '京族',
    '土家族',
    '黎族',
    '畲族',
    '高山族'
]
